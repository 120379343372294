@import "../sass/variaveis";
@import "../sass/utilities/reset";

@import "../sass/mixins"; //MIXINS
@import "../sass/file_lira_mixins"; //MIXINS
@import "../sass/utilities/animation";

// COMPONENTS
@import "../sass/components/body"; //BODY
@import "../sass/components/menu/menu";
@import "../sass/components/footer";
@import "../sass/components/login";

// SECTIONS FILE
@import "../sass/sections/file_do_lira/file_style.scss";

// PAGES
@import "../sass/pages/home.scss";

// STYLE SALEBRAND
// @import "../sass/sections/salebrand/salebrand_style";

main {
  overflow: hidden;
}

html[lang="pt-br"],

html[lang="pt"]{
  .cardapio[data-cardapio="PT"]{
    display: flex !important;
    @include d(md2){
      display: none !important;
    }
  }
  .cardapio-mobile[data-cardapio="PT"]{
    display: none !important;
    @include d(md2){
      display: flex !important;
    }
  }
}

html[lang="en"]{
  .cardapio[data-cardapio="EN"]{
    display: flex !important;
    @include d(md2){
      display: none !important;
    }
  }
  .cardapio-mobile[data-cardapio="EN"]{
    display: none !important;
    @include d(md2){
      display: flex !important;
    }
  }
}

[data-lang]{
  display: none;
}

html[lang="pt-br"],
html[lang="pt"]{
  [data-lang = 'pt']{
    display: block;
  }
}

html[lang="en"]{
  [data-lang = 'en']{
    display: block;
  }

  .hidden-title-en{
    display: none;
  }
}